const Categories = {
    SUPPORTERS: "SUPPORTERS",
    TESTERS: "TESTERS",
    PARTNER_ORGS: "PARTNER_ORGS"
};

const CategoryLabels = {
    SUPPORTERS: "Supporters",
    TESTERS: "Testbeds",
    PARTNER_ORGS: "Partner Organisations"
};

const partners = [
      {
        name: "Asuera Stiftung",
        logo: "Logo_Asuera_RGB.png",
        url: "https://www.asuerastiftung.ch/projekte/swiss-edtech-collider-swiss-national-edtech-testbed-program/",
        description: ["Partner_Asuera Stiftung"],
        categories: [Categories.SUPPORTERS]
    },
    {
        name: "BeLEARN",
        logo: "logo_belearn.jpg",
        url: "https://belearn.swiss/en/",
        description: ["Partner_BeLEARN"],
        categories: [Categories.PARTNER_ORGS]
    },
    {
        name: "Bexio",
        logo: "Bexio_logo_23.png",
        url: "https://www.bexio.com/en-CH/",
        description: ["Partner_Bexio"],
        categories: [Categories.PARTNER_ORGS]
    },
    {
        name: "CVCI",
        logo: "Cvci_logo_23.png",
        url: "https://www.cvci.ch/en/wwwcvcich/la-cvci/who-are-we.html",
        description: ["Partner_CVCI"],
        categories: [Categories.PARTNER_ORGS]
    },
    {
        name: "Credit Suisse Foundation",
        logo: "Creditsuisse_logo_23.png",
        url:
            "https://www.credit-suisse.com/about-us/en/our-company/corporate-responsibility/economy-society/switzerland/jubilee-fund.html",
        description: ["Partner_CreditSuisse"],
        categories: [Categories.SUPPORTERS]
    },
    {
        name: "Digitalswitzerland",
        logo: "Digitalswitzerland_logo_23.jpeg",
        url: "https://digitalswitzerland.com/",
        description: ["Partner_DigitalSwitzerland"],
        categories: [Categories.PARTNER_ORGS]
    },
    {
        name: "Ecole Moser",
        logo: "EcoleMoser_logo_23.png",
        url: "https://www.ecolemoser.ch",
        description: ["Partner_EcoleMoser"],
        categories: [Categories.PARTNER_ORGS]
    },
    {
        name: "Educa",
        logo: "Educa_logo_23.jpg",
        url: "https://www.educa.ch/en",
        description: ["Partner_Educa"],
        categories: [Categories.PARTNER_ORGS]
    },
    {
        name: "EEA European EdTech Alliance",
        logo: "Eea_logo_23.jpeg",
        url: "https://www.edtecheurope.org/",
        description: ["Partner_EEA"],
        categories: [Categories.PARTNER_ORGS]
    },
    {
        name: "EPFL",
        logo: "Epfl_logo_23.png",
        url: "https://www.epfl.ch/en/",
        description: ["Partner_EPFL"],
        categories: [Categories.SUPPORTERS]
    },
    {
        name: "EPFL Innovation Park",
        logo: "Eip_logo_23.jpeg",
        url: "https://epfl-innovationpark.ch/",
        description: ["Partner_EPFLInnovationPark"],
        categories: [Categories.SUPPORTERS]
    },
    {
        name: "Fondation de la CVCI",
        logo: "Cvci_logo_23.png",
        url: "http://fondationhenrimoser.ch/",
        description: ["Partner_FondationCVCI"],
        categories: [Categories.SUPPORTERS]
    },
    {
        name: "Fondation Henri Moser",
        logo: "Ecolemoser_logo_23.png",
        url: "http://fondationhenrimoser.ch/",
        description: ["Partner_FondationHenriMoser"],
        categories: [Categories.SUPPORTERS]
    },
    {
        name: "Gebert Rüf Stiftung",
        logo: "Gebertruef_logo_23.png",
        url: "https://www.grstiftung.ch/de.html",
        description: ["Partner_GebertRufStiftung"],
        categories: [Categories.SUPPORTERS]
    },
    {
        name: "IMD Business School Lausanne",
        logo: "Imd_logo_23.jpg",
        url: "https://www.imd.org",
        description: ["Partner_IMD"],
        categories: [Categories.PARTNER_ORGS]
    },
    {
        name: "International School of Lausanne ISL",
        logo: "Isl_logo_23.png",
        url: "https://www.isl.ch/",
        description: ["Partner_ISL"],
        categories: [Categories.TESTERS]
    },
    {
        name: "Innovaud",
        logo: "Innovaud_logo_23.jpeg",
        url: "https://www.innovaud.ch/en/",
        description: ["Partner_Innovaud"],
        categories: [Categories.PARTNER_ORGS]
    },
    {
        name: "Jacobs Foundation",
        logo: "Jacobsfoundation_logo_23.png",
        url: "https://jacobsfoundation.org/en/",
        description: ["Partner_JacobsFoundation"],
        categories: [Categories.SUPPORTERS]
    },
    {
        name: "LEARN Center for Learning Sciences",
        logo: "Learn_logo_23.png",
        url:
            "https://www.epfl.ch/education/educational-initiatives/center-learn/",
        description: ["Partner_LEARN"],
        categories: [Categories.PARTNER_ORGS]
    },
    {
        name: "Lémania Swiss Group of Schools",
        logo: "lemania_logo_23.jpeg",
        url: "https://www.lemania.ch/en/presentation/lemania-group/",
        description: ["Partner_Lemania"],
        categories: [Categories.TESTERS]
    },
    {
        name: "Lycée Jean-Piaget",
        logo: "Ljp_logo_23.png",
        url: "https://www.lyceejeanpiaget.ch/Pages/home.aspx",
        description: ["Partner_LyceeJeanPiaget"],
        categories: [Categories.TESTERS]
    },
    {
        name: "Pädagogische Hochschule Zürich",
        logo: "phzh_logo_kooperation.png",
        url: "https://phzh.ch",
        description: ["Partner_PHZH"],
        categories: [Categories.PARTNER_ORGS]
    },
    {
        name: "Schulverlag plus AG",
        logo: "Schulverlag_logo_2024.jpeg",
        url: "https://www.schulverlag.ch/de/produkte/support/meine-lehrmittel/meinprojekt/",
        description: ["Partner_Schulverlag"],
        categories: [Categories.PARTNER_ORGS]
    },
    {
        name: "Swiss Accelerator Network SAN",
        logo: "Swissacceleratornetwork_logo_23.png",
        url: "https://digitalswitzerland.com/sub-programm/swiss-accelerator-network/",
        description: ["Partner_SAN"],
        categories: [Categories.PARTNER_ORGS]
    },
    {
        name: "Swisscom",
        logo: "Swisscom_logo_23.jpeg",
        url: "https://www.swisscom.ch/en/about.html",
        description: ["Partner_Swisscom"],
        categories: [Categories.SUPPORTERS]
    }
];

export default partners;
export { Categories, CategoryLabels, partners };
